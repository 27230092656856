<template>
  <v-row no-gutters>
    <v-col cols="12" v-if="$store.state.reminders.retrieveLoader">
      <v-skeleton-loader transition="fade-transition" type="list-item-three-line" />
    </v-col>
    <v-col cols="12" v-else>
      <template v-if="templatesList.length">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Creado por</th>
                <th class="text-right">Fecha de creación</th>
                <th class="text-right">Última modificación</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(d, i) in templatesList">
              <tr :key="i" @click="template=d, modalTemplate=true">
                <td>
                  <span class="body-2 grey-500--text font-weight-medium">{{d.name}}</span>
                </td>
                <td>
                  <span>{{d.user}}</span>
                </td>
                <td class="text-right">{{d.created | dateTime}}</td>
                <td class="text-right">{{d.updated | dateTime}}</td>
                <td class="text-right">
                 <v-btn @click.stop="deleteTemplate(i)" icon color="red" retain-focus-on-click :ripple="false"><v-icon small>mdi-close</v-icon></v-btn>
                </td>
              </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
        <v-divider />
        <v-col md="12" class="text-center py-5">
          <v-btn @click="modalTemplate=true" outlined>Nueva plantilla</v-btn>
        </v-col>
      </template>
      <v-col class="text-center py-5" v-else>
        <v-icon class="d-block" color="grey-500" size="70">mdi-email</v-icon>
        <span class="d-block subtitle-1">Aún no tienes plantillas para {{kind === 'collection' ? 'recordatorios de pago' : 'envío de facturas'}}.</span>
        <v-btn class="my-5" @click="modalTemplate=true" outlined>Crear una plantilla</v-btn>
      </v-col>
    </v-col>

    <!-- dialog reminders templates -->
    <v-dialog v-model="modalTemplate" width="800" persistent no-click-animation overlay-color="grey-500">
      <v-card>
        <v-toolbar color="transparent" flat height="55">
          <v-toolbar-title class="grey-500--text subtitle-1 font-weight-medium">{{modalTemplate.id ? 'Editar plantilla' : 'Nueva plantilla'}}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="pr-1">
              <v-btn @click="modalTemplate=false" icon small :ripple="false"><v-icon small>mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="pa-0">
          <v-row class="mx-0" align="center" justify="start">
            <v-col cols="6" class="px-4" v-for="message in messages" :key="message.value">
              <v-card class="pt-1 pb-0">
                <v-radio-group v-model="channel" :disabled="message.value === 'sms'" :mandatory="false" class="py-0">
                  <v-radio class="px-3 grey-500--text font-weight-medium" :value="message.value" :label="message.label" />
                  <span class="ml-11">{{message.text}}</span>
                </v-radio-group>
              </v-card>
            </v-col>
          </v-row>
          <v-divider />
          <v-list class="transparent">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="grey-500--text font-weight-medium">Nombre de la plantilla</v-list-item-title>
                <v-list-item-subtitle class="pt-1">
                  <v-text-field v-model.trim="$v.template.name.$model" :error="$v.template.name.$error" outlined required single-line dense maxlength="40" placeholder="Escribe un nombre para tu plantilla" :hide-details="$v.template.name.maxLength" persistent-hint hint="Escribe un máximo de 40 caracteres" />
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="grey-500--text font-weight-medium">Asunto</v-list-item-title>
                <v-list-item-subtitle class="pt-1">
                    <v-text-field v-model.trim="$v.template.subject.$model" :error="$v.template.subject.$error" outlined required single-line dense maxlength="40" placeholder="Escribe un asunto para el email" :hide-details="$v.template.subject.maxLength" persistent-hint hint="Escribe un máximo de 60 caracteres" />
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-row class="mx-0">
            <v-col class="px-4">
              <span class="font-weight-medium grey-500--text">Mensaje</span>
              <v-card class="pa-0 background elevation-0" style="border:1px solid #D8E0EA !important">
                <v-row class="ma-0">
                  <v-spacer />
                  <v-divider vertical />
                    <v-menu v-model="menu" right :close-on-content-click="false" transition="scale-transition" offset-y min-width="180px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" color="white" :ripple="false" tile style="border-top-right-radius:4px">Insertar token<v-icon>mdi-menu-down</v-icon></v-btn>
                      </template>
                      <template v-for="(d, i) in tokens">
                        <v-col class="py-1" :key="i" v-if="d.kind.includes(kind)">
                          <a class="body-2 grey-500--text" @click="setToken(i); menu=false" v-html="d.label"></a>
                        </v-col>
                      </template>
                    </v-menu>
                  </v-row>
                <v-divider />
                <v-col class="py-0 white" :style="$v.template.message.$error ? 'border:1px solid #ff5252 !important' : ''">
                  <v-textarea class="pb-0 elevation-0" v-model.trim="$v.template.message.$model" solo flat hide-details  placeholder="Escribe un mensaje para el email" ref="message" />
                </v-col>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn @click="modalTemplate=false" outlined color="grey-500">Cancelar</v-btn>
          <v-btn @click="template.id ? patchTemplate() : postTemplate()" :loading="$store.state.documents.createLoader" color="blue-500">Guardar plantilla</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog reminders templates -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { required, maxLength } from 'vuelidate/lib/validators'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import cloneDeep from 'lodash/cloneDeep'
export default {
  props: {
    kind: {
      type: String,
      required: true
    }
  },
  mixins: [
    GenericViewMixin
  ],
  data () {
    return {
      deleteTemp: null,
      menu: false,
      accountConfig: {},
      messages: [
        { text: 'Envío de mensaje a través de email.', value: 'email', label: 'Mensaje de email' },
        { text: 'Envío de mensaje a través de mensaje de texto.', value: 'sms', label: 'Mensaje de SMS' }
      ],
      template: {
        id: null,
        kind: this.kind,
        name: '',
        subject: '',
        message: ''
      },
      tokens: [
        { value: 'number', label: '<b>Factura</b> número', kind: ['documents', 'collection'] },
        { value: 'document.name', label: '<b>Factura</b> tipo', kind: ['documents', 'collection'] },
        { value: 'date', label: '<b>Factura</b> fecha', kind: ['documents', 'collection'] },
        { value: 'due_date', label: '<b>Pago</b> fecha', kind: ['collection'] },
        { value: 'total', label: '<b>Pago</b> total', kind: ['collection'] },
        { value: 'total', label: '<b>Factura</b> total', kind: ['documents'] },
        { value: 'customer.name', label: '<b>Cliente</b> nombre', kind: ['documents', 'collection'] }
      ],
      channel: 'email',
      modalTemplate: false
    }
  },
  computed: mapState({
    templatesList: state => cloneDeep(state.reminders.templatesList)
  }),
  watch: {
    modalTemplate () {
      if (!this.modalTemplate) {
        this.template = {
          id: null,
          kind: this.kind,
          name: '',
          subject: '',
          message: ''
        }
        this.$v.template.$reset()
      }
    }
  },
  methods: {
    postTemplate () {
      this.$v.template.$touch()
      if (this.$v.template.$invalid) {
        return false
      }
      this.$store.dispatch('reminders/CREATE', {
        resource: 'reminders/templates',
        payload: this.template
      })
      .finally(() => {
        this.modalTemplate = false
        this.$dialog.message.info('La plantilla se ha creado con éxito')
      })
    },
    patchTemplate () {
      this.$v.template.$touch()
      if (this.$v.template.$invalid) {
        return false
      }
      this.$store.dispatch('reminders/UPDATE', {
        resource: 'reminders/templates',
        id: this.template.id,
        payload: this.template
      })
      .finally(() => {
        this.modalTemplate = false
        this.$dialog.message.info('La plantilla se ha actualizado con éxito')
      })
    },
    deleteTemplate (i) {
      this.$dialog.warning({
        title: 'Eliminar',
        text: `
          <span class="d-block">Al confirmar se eliminará la plantilla de tu cuenta y todo lo asociado con esta utilizará un mensaje por defecto.</span>
        `,
        actions: [
          {
            color: 'red',
            text: 'Continuar',
            handle: () => {
              this.$store.dispatch('reminders/DESTROY', {
                resource: 'reminders/templates',
                id: this.templatesList[i].id
              })
              .then((response) => {
                this.$dialog.message.info('La plantilla ha sido eliminada')
              })
              .catch((error) => {
                this.$dialog.message.error(this.parseErrors(error.response.data))
              })
            }
          }
        ]
      })
    },
    setToken (i) {
      let message = this.template.message
      const textarea = this.$refs.message.$refs.input
      const sentence = textarea.value
      const len = sentence.length
      let start = textarea.selectionStart
      if (start === undefined) {
        start = 0
      }
      this.template.message = sentence.substr(0, start) + '{{' + this.tokens[i].value + '}}' + sentence.substr(start, len)
      this.$nextTick().then(() => {
        textarea.selectionStart = start + message.length
      })
    }
  },
  validations: {
    template: {
      name: {
        required,
        maxLength: maxLength(40)
      },
      subject: {
        required,
        maxLength: maxLength(60)
      },
      message: {
        required
      }
    }
  }
}
</script>